.star-rating button {
	background-color: transparent;
	border: none;
	font-size: 40px;
}

.star-rating {
	display: flex;
	align-items: center;
}

.confirmation-buttons button {
	margin-left: 0.5rem;
}

.on {
	color: #e6c462;
}

.off {
	color: rgb(161, 161, 161);
}

.disabled {
	pointer-events: none;
}

.star:hover {
	font-size: 42px;
}
