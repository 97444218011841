.nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.nav nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 0.55rem;
}

.header h1 {
	margin-bottom: 1.5rem;
}

.filters button {
	display: flex;
	width: 6rem;
	justify-content: space-around;
	align-items: center;
	background-color: transparent;
	border: none;
	color: rgb(199, 199, 199);
	font-size: 20px;
	border: 1px solid rgb(199, 199, 199);
	padding: 0.5rem;
	cursor: pointer;
}

.filters button:hover {
	color: white;
	border: 1px solid white;
}

.nav ul {
	list-style: none;
	width: 38%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nav ul li a {
	text-decoration: none;
	color: rgb(199, 199, 199);
	font-weight: bold;
	padding-bottom: 0.2rem;

}

.nav ul li a:hover {
	color: white;
}

.active {
	border-bottom: 0.3rem solid #e6c462;
}

.filters-dropdown {
	border: 2px solid #e6c462;
	width: 100%;
	margin-top: 0.5rem;
	display: flex;
	justify-content: space-between;
	padding: 0.5rem;
}


.entity-list ul {
	list-style: none;
}

.entity-list ul li {
	margin-top: 1rem;
}

.entity-list ul li a {
	text-decoration: none;
	color: white;
}
