.main {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.main label {
	font-weight: bold;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 26px;
}

.main input {
	width: 20%;
	border-radius: 5px;
	font-size: 24px;
	margin-bottom: 0.2rem;
    padding: 0.3rem;
}

.invalid {
	border: 2px solid red;
	background-color: rgba(255, 0, 0, 0.26);
}

.error-msg {
	font-weight: bold;
	color: red;
}
