.main-info-rating {
	font-size: 20px;
	display: flex;
	margin-top: 1.5rem;
    align-items: center;
}

.main-info h2 {
	font-size: 2rem;
}

.main-info p {
	font-size: 1.5rem;
}

.main-info-rating-value {
	font-weight: bold;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.star {
	font-size: 40px;
	color: #e6c462;
}

.rating-section {
	margin-top: 2rem;
	display: flex;
	justify-content: space-between;
}

.ranking-place {
	display: flex;
	font-size: 1.25rem;
	padding: 0 0.6rem;
	border: 3px solid #e6c462;
	align-items: center;
	justify-content: center;
}

.ranking-place p {
	margin-left: 0.5rem;
}

.user-rating {
	display: flex;
	align-items: center;
}

.user-favourites {
	margin-left: 2rem;
}

.user-favourites i {
	cursor: pointer;
}

.heart-off {
	font-size: 30px;
	color: rgb(119, 119, 119);
}

.heart {
	font-size: 30px;
	transition: all 0.1s ease-in;
}

.heart:hover {
	color: red;
}

.heart-on {
	font-size: 30px;
	color: red;
}

.detailed-info {
	margin-top: 4rem;
}

.detailed-info div {
	margin-top: 0.75rem;
}


.list-section{
	margin-top: 5rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.details-list{
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.details-list h3{
	margin-bottom: 1.5rem;
}

.details-list ul{
	list-style: none;
	width: 100%;
	overflow: scroll;
	max-height: 20rem;
}
