.main {
	display: flex;
	font-weight: bold;
	justify-content: space-between;
	text-decoration: none;
	color: black;
}

.main p {
	display: inline-block;
}

.name {
	display: flex;
	/* width: 80%; */
	justify-content: flex-start;
	align-items: center;
}

.name p {
	display: inline-block;
}
