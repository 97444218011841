.main {
	display: flex;
}

.info {
	margin-left: 1rem;
}

.info h4 {
	font-size: 1.25rem;
}

.info p {
	font-size: 1.25rem;
}
