.filters-dropdown {
	border: 2px solid #e6c462;
	width: 100%;
	margin-top: 0.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem;
}

.rating-filter {
	margin-left: 1rem;
}

.epoch-filter {
	width: 35%;
}

.epoch-filter ul {
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.epoch-filter button {
	margin: 0.2rem;
	border: 1px solid #e6c462;
	padding: 0.4rem;
	width: 7.5rem;
	text-align: center;
	background-color: transparent;
	font-weight: bold;
	color: rgb(199, 199, 199);
	text-transform: uppercase;
}

.epoch-filter button:hover {
	color: white;
}

.epoch-filter .active {
	background-color: #e6c462;
	color: rgb(27, 27, 27);
}

