.main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.main h1 {
	margin-bottom: 2rem;
}

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.input-row {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.input-row label {
	font-weight: bold;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	font-size: 26px;
}

.input-row input {
	border-radius: 5px;
	font-size: 24px;
	margin-bottom: 0.5rem;
	padding: 0.3rem;
}

.buttons {
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
}

.buttons button {
	padding: 0.3rem;
	font-size: 20px;
	margin-bottom: 0.4rem;
	width: 10rem;
	background-color: transparent;
	color: white;
	border: 2px solid white;
	border-radius: 10px;
	transition: all 0.25s ease-in;
}

.submit:hover {
	border: 2px solid lightgreen;
	color: lightgreen;
}

.cancel:hover {
	border: 2px solid rgb(255, 58, 58);
	color: rgb(255, 58, 58);
}
