.user-favourites {
	margin-left: 2rem;
}

.user-favourites i {
	cursor: pointer;
}

.heart-off {
	font-size: 30px;
	color: rgb(119, 119, 119);
}

.heart {
	font-size: 30px;
	transition: all 0.1s ease-in;
}

.heart:hover {
    color: red;
}

.disabled{
	pointer-events: none;
}

.heart-on {
	font-size: 30px;
	color: red;
}