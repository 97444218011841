
.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 0.55rem;
}

.header h1{
    margin-bottom: 1.5rem;
}

.nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.nav nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nav ul {
	list-style: none;
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nav ul li a {
	text-decoration: none;
	color: rgb(199, 199, 199);
	font-weight: bold;
	padding-bottom: 0.2rem;
	padding-right: 0.5rem;
}

.nav ul li a:hover {
	color: white;
}

.active {
	border-bottom: 0.3rem solid #e6c462;
}

.ranking-list {
    margin-top: 3rem;
}

.ranking-list ul{
    list-style: none;
}
