.main {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.main h1 {
	margin-bottom: 2rem;
}

.main form {
	width: 100%;
}

.buttons {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.buttons button {
	width: 20%;
	padding: 0.3rem 0;
	background-color: transparent;
	border-radius: 10px;
	margin-bottom: 1rem;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 20px;
	transition: all 0.3s ease-out;
}

.buttons button:hover {
	background-color: white;
	border: 2px solid white;
}
