.main {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main h2 {
	margin-bottom: 1.5rem;
	margin-top: 2rem;
}

.buttons ul {
	list-style: none;
	padding-left: 0;
	display: flex;
	align-content: space-between;
}

.buttons ul li {
	margin: 0 0.5rem;
}

.buttons button {
	padding: 0.3rem;
	background-color: transparent;
	font-size: 20px;
	border: none;
}

.inactive{
	color: white;
}

.active {
	color: #e6c462;
	font-size: 24px;
}

.buttons {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.arrow{
	color: white;
}
