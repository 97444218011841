.navigation {
	width: 100%;
}

.navigation ul {
	list-style: none;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.navigation ul li {
	font-size: 20px;
	padding: 0 3rem;
	font-weight: bold;
	transition: all 0.3 ease-out;
}

.navigation ul li a {
	text-decoration: none;
	color: #0c0c0c;
	color: white;
	transition: all 0.3s ease;
}

.navigation ul li a:hover {
	color: #d41900;
}

.navigation-header {
	margin-bottom: 5rem;
	display: flex;
	flex-direction: column;
	background-color: #0c0c0c;
}

.navigation-logo-row {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	background-color: transparent;
	padding-left: 20px;
	padding-top: 10px;
	color: white;
}

.navigation-logo-row h1 span {
	color: #d41900;
}

.navigation-row {
	display: flex;
	justify-content: space-between;
	background-color: transparent;
	padding: 2rem 2rem;
	margin: 0 10rem;
}

.search-bar {
	display: inline-block;
	width: 60%;
}

.auth-buttons {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 15%;
}

.logout {
	font-size: 2rem;
	color: white;
	background-color: transparent;
	border: none;
	cursor: pointer;
	margin-left: 2.5rem;
}

.logout:hover {
	font-size: 2.1rem;
}

.account:hover {
	font-size: 2.1rem;
}

.logged-in {
	font-size: 2rem;
	color: white;
	display: flex;
	justify-content: space-between;
}

.auth-buttons a {
	text-decoration: none;
	color: white;
	transition: all 0.3s ease;
	font-size: 20px;
	padding: 0.3rem 0.7rem;
	border-radius: 25px;
	font-weight: bold;
	border: 1px solid black;
	transition: all 0.3 ease-in;
}

.auth-buttons a:hover {
	background-color: white;
	border: 1px solid white;
}

.search-bar input {
	font-size: 40px;
	border-radius: 20px;
	width: 90%;
	padding: 5px 10px;
}

.ranking {
	background-color: transparent;
	border: none;
	font-weight: bold;
	font-size: 20px;
	font-family: "Montserrat", "sans-serif";
}

.ranking-dropdown {
	background-color: white;
	z-index: 100;
	position: absolute;
	transition: all 0.3 ease-out;
}

.ranking-dropdown-closed {
	opacity: 0;
}

.ranking-dropdown-opened {
	opacity: 1;
}

.ranking-dropdown ul {
	list-style: none;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.ranking-dropdown ul li a {
	color: #0c0c0c;
}
