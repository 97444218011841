.main {
	border: 2px solid #e6c462;
}

.badge-label {
	color: #e6c462;
	/* font-weight: bold; */
	letter-spacing: 1px;
	position: relative;
	top: -10px;
	left: 130px;
	background-color: #0c0c0c;
	display: inline-block;
	text-transform: uppercase;
}

.item-outer {
	display: flex;
}

.item-outer img {
	max-width: 140px;
}

.item-inner {
	margin-top: 1rem;
	padding-left: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.item-rating {
	margin-bottom: 0.5rem;
	display: flex;
	align-items: center;
}

.rating-value {
	margin-left: 0.5rem;
	font-weight: bold;
	text-align: center;
	margin-right: 0.5rem;
}

.star {
	color: #e6c462;
	font-size: 2rem;
	text-align: center;
}

.rendition-info {
	display: flex;
	align-items: center;
}

.rendition-musician {
	margin-left: 0.5rem;
}

.rendition-piece {
	margin-right: 0.5rem;
}
