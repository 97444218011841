@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,800;1,200;1,400&display=swap");

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	color: transparent;
}



::-webkit-scrollbar-thumb {
	background-color: black;
	border-radius: 30px;
	border: 6px solid white;
	background-clip: content-box;
}


* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* second color: #e6c462 */

body {
	background-color: #e6c462;
	font-family: "Montserrat", "sans-serif";
	color: white;
}

/* HomePage styling */

.home-page-main {
	background-color: #0c0c0c;
	margin: 5px;
}

.outer {
	border: 2px solid #e6c462;
	margin-left: 10rem;
	margin-right: 10rem;
}

.active {
	color: whitesmoke;
}

.page-main {
	background-color: #0c0c0c;
	margin: 0 20rem;
	margin-bottom: 10rem;
	padding: 2.5rem 4rem;
	border-radius: 8px;
}

.row {
	font-weight: bold;
	display: flex;
	justify-content: space-between;
}

.red {
	color: #d41900;
}
