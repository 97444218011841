.main {
    margin: 0.5rem 0;
	display: flex;
	border: 1px solid #e6c462;
}

.ranking-place {
	font-weight: bold;
	font-size: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 2rem;
}

.main-info{
    margin: 1.25rem 2rem;
}

.rating{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 1.5rem;
    margin-right: 1rem;
}

.rating-inner{
    display: flex;
    align-items: center;
}

.rating-inner span{
    font-size: 2.5rem;
    color: #e6c462;
}

.rating-inner h5{
    font-size: 2rem;
    margin-left: 0.5rem;
}


.main-inner{
    display: flex;
    justify-content: space-between;
    width: 100%;
}



img {
	max-height: 10rem;
}


