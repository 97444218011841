.dropdown {
	margin-left: 0.4rem;
	padding: 0.5rem 1rem;
	width: 41%;
	background-color: white;
	position: absolute;
	z-index: 100;
	margin-top: 3.3rem;
}

.dropdown-all {
	margin-left: 0.4rem;
	padding: 0.5rem 1rem;
	width: 49%;
	background-color: white;
	position: absolute;
	z-index: 100;
	/* max-height: 19.2rem; */
}

.dropdown-all ul {
	list-style: none;
}

.dropdown ul {
	list-style: none;
}

.dropdown-item {
	border: 1px solid black;
	justify-content: space-between;
	padding: 0.2rem 0.2rem;
	font-weight: bold;
	font-size: 24px;
	position: relative;
}

.dropdown-item a {
	text-decoration: none;
	color: black;
	position: relative;
}

.dropdown-item-inner {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.dropdown-item-inner p {
	padding-left: 0.2rem;
	padding-right: 0.2rem;
}
