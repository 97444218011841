.main {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main h2 {
	margin-top: 2rem;
	margin-bottom: 1rem;
}

.show-more {
	background-color: transparent;
	width: 80%;
	height: 2rem;
	border: 5px solid black;
	border-top: none;
	background-color: rgba(0, 0, 0, 0.2);
}

.list {
	width: 80%;
	padding: 1rem;
	padding-bottom: 0;
	border: 5px solid black;
	border-bottom: none;
	border-radius: 5px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.list ul {
	list-style: none;
}
