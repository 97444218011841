.piece {
	display: flex;
	flex-direction: column;
	width: 40%;
	border: 5px solid #e6c46c;
	padding: 1rem;
}

.piece h3 {
	font-size: 1.5rem;

	color: rgba(255, 255, 255, 0.8);
}
.piece h3:hover {
	color: white;
}

.piece h5 {
	margin-bottom: 0.5rem;
}

.composer h4 {
	color: rgba(255, 255, 255, 0.8);
}

.composer h4:hover {
	color: white;
}

.main {
	display: flex;
	justify-content: space-between;
	margin-top: 2.5rem;
	margin: 4rem 1rem;
}

.musician {
	width: 40%;
	display: flex;
	justify-content: flex-end;
	border: 5px solid #e6c46c;
	padding: 1rem;
}

.musician-inner {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
    justify-content: space-between;
	margin-right: 1.5rem;
}

.musician-inner h3 {
	color: rgba(255, 255, 255, 0.8);
	font-size: 1.5rem;
}

.musician-inner h3:hover {
	color: white;
}

.musician-inner h5 {
	margin-bottom: 0.5rem;
}

.video{
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video h5{
    font-size: 2.25rem;
    margin-bottom: 2rem;
}

.video iframe{
    border: 5px solid #e6c46c;
}
